import { useState, useEffect } from "react";
import Typography from "../../../../components/Typography";
import LargeForm from "./LargeForm";
import SmallForm from "./SmallForm";
import { ScheduleFormContainer, FormWrapper } from "./styled";

export default function ScheduleForm({ variant, title, padding, submitText, formId }) {
  const variants = ["small", "large"];
  variant = variants.includes(variant) ? variant : variants[0];
  const [showSmall, setShowSmall] = useState(true);

  useEffect(() => {
    setShowSmall(variant === "small");
  }, [variant]);

  return (
    <FormWrapper>
    <ScheduleFormContainer padding={padding}>
      {showSmall && <SmallForm formId={formId} submitText={submitText} />}
      {!showSmall && <LargeForm formId={formId} />}
    </ScheduleFormContainer>
    </FormWrapper>
  );
}
