// styled.js
import styled from "styled-components";

export const Container = styled.div`
  padding-top: 50px;
`;

export const Content = styled.div`
  position: relative;

  .title-info {
    @media only screen and (min-width: 1025px) {
      max-width: 480px;
      margin-left: -75px;
      margin-top: 20px;
    }
    max-width: 100%;
    padding-left: 0px;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1025px) {
  left: -75px;
    top:  20px;
}

  .ant-form-item-explain {
    color: white;
  }
`;

export const FormHeading = styled.div`
  color: #ffffff;
  background-color: #762738;
  width: 100%;
  text-align: center;
  padding: 20px 10px 10px 10px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2em;
  max-width: 100%;

  @media only screen and (min-width: 1025px) {
    margin-top: 0;

  }
`;
